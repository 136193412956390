#root {
  margin: 30px;
}

.label {
  margin-bottom: 10px;
  display: block;
}

.input {
  margin: 0 10px;
}

.button {
  display: block;
}

.button-group > * {
  margin: 8px 0;
}
/*# sourceMappingURL=index.77d0206a.css.map */
